var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('div',{staticClass:"modal fade",attrs:{"id":"modalTipoHistorias","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-lg modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"card-waves "},[_vm._m(1),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Codigo")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.codigo),expression:"tipos.codigo"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.tipos.codigo.$error && _vm.$v.tipos.codigo.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.tipos.codigo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tipos, "codigo", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-12 col-md-12 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Nombre")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.nombre),expression:"tipos.nombre"}],staticClass:"form-control form-control-sm",class:{
                        'is-invalid':
                          _vm.$v.tipos.nombre.$error && _vm.$v.tipos.nombre.$dirty,
                      },attrs:{"type":"text"},domProps:{"value":(_vm.tipos.nombre)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tipos, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Path")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.path),expression:"tipos.path"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.tipos.path.$error && _vm.$v.tipos.path.$dirty,
                    },attrs:{"type":"text"},domProps:{"value":(_vm.tipos.path)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tipos, "path", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Tipo de edad inicio")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.tipo_edad_inicio),expression:"tipos.tipo_edad_inicio"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.tipos.tipo_edad_inicio.$error && _vm.$v.tipos.tipos_edad_inicio.$dirty,
                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipos, "tipo_edad_inicio", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"A"}},[_vm._v("A")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M")]),_c('option',{attrs:{"value":"D"}},[_vm._v("D")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Inicio de edad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.edad_inicio),expression:"tipos.edad_inicio"}],staticClass:"form-control form-control-sm",class:{
                        'is-invalid':
                          _vm.$v.tipos.edad_inicio.$error && _vm.$v.tipos.edad_inicio.$dirty,
                      },attrs:{"type":"number"},domProps:{"value":(_vm.tipos.edad_inicio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tipos, "edad_inicio", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Tipo de edad final")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.tipo_edad_final),expression:"tipos.tipo_edad_final"}],staticClass:"form-control form-control-sm",class:{
                        'is-invalid':
                          _vm.$v.tipos.tipo_edad_final.$error && _vm.$v.tipos.tipo_edad_final.$dirty,
                      },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipos, "tipo_edad_final", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"A"}},[_vm._v("A")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M")]),_c('option',{attrs:{"value":"D"}},[_vm._v("D")])])]),_c('div',{staticClass:"form-group col-lg-4 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Final de edad")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.edad_final),expression:"tipos.edad_final"}],staticClass:"form-control form-control-sm",class:{
                          'is-invalid':
                            _vm.$v.tipos.edad_final.$error && _vm.$v.tipos.edad_final.$dirty,
                        },attrs:{"type":"number"},domProps:{"value":(_vm.tipos.edad_final)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tipos, "edad_final", $event.target.value)}}})]),_c('div',{staticClass:"form-group col-lg-4 col-md-4 col-sm-12"},[_c('label',{staticClass:"form-label"},[_vm._v("Sexo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipos.sexo),expression:"tipos.sexo"}],staticClass:"form-control form-control-sm",class:{
                        'is-invalid':
                          _vm.$v.tipos.sexo.$error && _vm.$v.tipos.sexo.$dirty,
                      },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipos, "sexo", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"A"}},[_vm._v("A")]),_c('option',{attrs:{"value":"F"}},[_vm._v("F")]),_c('option',{attrs:{"value":"M"}},[_vm._v("M")])])])])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guardar"),_c('i',{staticClass:"fa fa-save fa-fw"})]),_c('button',{staticClass:"btn btn-light btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.cerrar()}}},[_vm._v(" Cerrar"),_c('i',{staticClass:"fa fa-times-circle fa-fw"})])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Registro de Tipos de Hisorias ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header bg-light text-dark"},[_c('i',{staticClass:"fa fa-book fa-fw text-primary"}),_vm._v("Datos")])}]

export { render, staticRenderFns }